import React, { useEffect, useState } from 'react';
import './SplashScreen.css'; // Archivo CSS para las animaciones y estilos

import imgLogo from '../assets/img/logo.png';

const SplashScreen = ({ onLoadComplete }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [randomPhrase, setRandomPhrase] = useState('');

  // Frases de seguridad vial y ciudadana
  const phrases = [
    "Conduce seguro, vive más.",
    "Respeta las señales, salva vidas.",
    "Tu seguridad es nuestra prioridad.",
    "Juntos por una ciudad más segura."
  ];

  // Selecciona una frase aleatoria al cargar el componente
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * phrases.length);
    setRandomPhrase(phrases[randomIndex]);

    const timer = setTimeout(() => {
      setIsExiting(true);
      setTimeout(onLoadComplete, 1000); // Espera 1 segundo más para completar la animación de salida
    }, 1900); // 5 segundos de duración

    return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
  }, [onLoadComplete]);

  return (
    <div className={`splash-screen ${isExiting ? 'slide-up' : 'fade-in'}`}>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <div className="text-center">
          {/* Logo con efecto de parpadeo */}
          <img 
            src={imgLogo}
            alt="Logo" 
            className="w-48 h-48" 
          />
          {/* <p className="mt-4 text-xl">{randomPhrase}</p> */}
        </div>
        <div className="loader mt-8"></div> {/* Círculo de carga */}
      </div>
    </div>
  );
};

export default SplashScreen;
