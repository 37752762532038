import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import './LoadingScreen.css';

const LoadingScreen = ({ onLoadComplete }) => {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    // Configura un temporizador para iniciar la animación de salida después de 2 segundos
    const timer = setTimeout(() => {
      setIsExiting(true);
      setTimeout(onLoadComplete, 1000); // Espera 1 segundo más para completar la salida
    }, 2000); // 2 segundos de duración

    return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
  }, [onLoadComplete]);

  return (
    <div className={`loading-container ${isExiting ? 'slide-out-top' : 'slide-in-bottom'}`}>
      <div className="text-center">
        <FaSpinner className="text-[#fff] text-4xl animate-spin mb-4" />
        <p className="text-lg font-semibold text-[#fff]">
          Espera mientras cargamos el servicio...
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;
