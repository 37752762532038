import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import MenuTabs from '../components/MenuTabs';
import Card from '../components/Card';
import InfoCard from '../components/InfoCard';
import BottomNavBar from '../components/BottomNavBar';
import LoadingScreen from '../components/LoadingScreen'; // Importa LoadingScreen

import imgCedula from '../assets/img/cedula.png';
import imgSri from '../assets/img/sri.png';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('Todos');
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga
  const cardContainerRef = useRef(null);
  const navigate = useNavigate();

  const allCards = [
    {
      image: imgCedula,
      title: "Multas de Tránsito",
      category: 'Todos',
      link: '/consultar-multas',
    },
    {
      image: imgSri,
      title: "Cédula de Identidad",
      location: "South America",
      rating: "4.7",
      category: 'Mas Visto',
      link: null,
    },
  ];

  const filteredCards = allCards.filter(card => card.category === activeTab || activeTab === 'Todos');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  };

  const handleCardClick = (link) => {
    if (link) {
      setIsLoading(true); // Mostrar pantalla de carga
      setTimeout(() => {
        setIsLoading(false); // Ocultar pantalla de carga
        navigate(link); // Navegar a la ruta proporcionada
      }, 2000); // Retraso de 2 segundos
    }
  };

  return (
    <div className="min-h-screen">
      <Header />
      {isLoading && <LoadingScreen />} {/* Mostrar LoadingScreen si isLoading es true */}
      <div className="m-2">
        <SearchBar />
        <InfoCard />
        <MenuTabs activeTab={activeTab} setActiveTab={handleTabChange} />
        <div ref={cardContainerRef} className="mt-5 mb-20 overflow-x-auto">
          <ul className="items flex space-x-1">
            {filteredCards.map((card, index) => (
              <li
                key={index}
                className="flex-shrink-0 cursor-pointer"
                onClick={() => handleCardClick(card.link)}
              >
                <Card
                  image={card.image}
                  title={card.title}
                  location={card.location}
                  rating={card.rating}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <BottomNavBar /> */}
    </div>
  );
};

export default Dashboard;
