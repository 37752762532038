import React from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // Ícono de regreso
import { useNavigate } from 'react-router-dom'; // Si usas React Router para la navegación

const HeaderGlobal = ({ title }) => {
  const navigate = useNavigate(); // Hook para manejar la navegación

  const handleGoHome = () => {
    navigate('/'); // Cambia '/' por la ruta de tu Home
  };

  return (
    <header className="bg-gray-800 text-white py-4 px-6 flex items-center shadow-md fixed top-0 left-0 w-full z-50">
      {/* Botón de regreso */}
      <button
        onClick={handleGoHome}
        className="text-white text-xl mr-4 hover:text-gray-200 transition-colors"
      >
        <FaArrowLeft />
      </button>

      {/* Título centrado */}
      <h1 className="text-2xl font-semibold flex-1 text-center">{title}</h1>
    </header>
  );
};

export default HeaderGlobal;
