import React from 'react';
import { FiHeart } from 'react-icons/fi';
import imgLogo from '../assets/img/logo.png'; // Importa el logo

import './Card.css';

const Card = ({ image, title }) => {
  return (
    <div className="relative w-full h-[380px] bg-white rounded-2xl shadow-lg overflow-hidden">
      {/* Imagen de fondo */}
      <img src={image} alt={title} className="w-full h-full object-cover" />

      {/* Logo centrado con opacidad */}
      <div className="absolute inset-0 flex items-center justify-center">
        <img
          src={imgLogo}
          alt="Logo"
          className="w-64 h-64 opacity-70" // Ajusta la opacidad (0.0 a 1.0)
        />
      </div>

      {/* Icono de corazón en la esquina superior derecha */}
      <div className="absolute top-3 right-3 bg-white p-2 rounded-full shadow">
        <FiHeart className="text-gray-700" />
      </div>

      {/* Fondo degradado en la parte inferior */}
      <div
        style={{
          background: "linear-gradient(to top, #1F2937, transparent)",
          height: "60px", // Ajusta este valor para controlar el desplazamiento del gradiente
        }}
        className="absolute bottom-0 left-0 right-0 p-4"
      >
        <h3 className="text-white text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

export default Card;
