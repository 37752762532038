import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../views/Home';
import ConsultaCedula from '../views/ConsultaCedula';
import ConsultarRUC from '../views/ConsultarRUC';
import ConsultarMultas from '../views/ConsultarMultas';
import ConsultarPlaca from '../views/ConsultarPlaca';
import ConsultarPropietario from '../views/ConsultarPropietario';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/consulta-cedula" element={<ConsultaCedula />} />
        <Route path="/consultar-ruc" element={<ConsultarRUC />} />
        <Route path="/consultar-multas" element={<ConsultarMultas />} />
        <Route path="/consultar-placa" element={<ConsultarPlaca />} />
        <Route path="/consultar-propietario" element={<ConsultarPropietario />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
