import React from 'react';

const InfoCard = () => {
  return (
    <div className="bg-gray-800 p-7 rounded-2xl mb-2 mt-4 shadow-md">
      <h2 className="text-md font-semibold text-white">¿No conoces la multa de tu vehículo?</h2>
      <p className="text-white text-sm mt-1">Explora aquí para obtener más información.</p>
      <button className="mt-2 px-3 py-1 bg-white text-gray-800 rounded-full hover:bg-gray-300 transition duration-200">
        Explorar aquí
      </button>
    </div>
  );
};

export default InfoCard;
