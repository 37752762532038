import React, { useState } from 'react';
import SplashScreen from '../components/SplashScreen';
import Dashboard from '../pages/Dashboard';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Cambia el estado a `false` cuando la pantalla de carga termine
  const handleLoadComplete = () => {
    setIsLoading(false);
  };

  return (
    <div className="relative">
      <Dashboard /> {/* El Dashboard está siempre cargado detrás */}
      {isLoading && <SplashScreen onLoadComplete={handleLoadComplete} />}
    </div>
  );
};

export default Home;
