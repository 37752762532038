import React from 'react';
import { FiSearch } from 'react-icons/fi';

const SearchBar = () => {
  return (
    <div className="flex items-center bg-white shadow-lg rounded-full px-4 py-2 border border-gray-300 w-full max-w-md">
      <FiSearch className="text-gray-500 text-xl" />
      <input
        type="text"
        placeholder="Buscar Aplicativo"
        className="flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400 py-1"
      />
    </div>
  );
};

export default SearchBar;
