import React, { useState, useEffect } from 'react';
import { FiBell } from 'react-icons/fi';

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [randomPhrase, setRandomPhrase] = useState('');
  const [greeting, setGreeting] = useState('Hola');

  // Frases motivadoras aleatorias para InfoTransito
  const phrases = [
    "¡Explora el tránsito sin complicaciones!",
    "InfoTransito: tu aliado en cada viaje.",
    "Conduce informado, llega seguro.",
    "La información vial que necesitas, cuando la necesitas.",
    "Haz que tu trayecto sea más fácil con InfoTransito.",
  ];

  // Selección aleatoria de una frase al cargar el componente
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * phrases.length);
    setRandomPhrase(phrases[randomIndex]);

    // Determinar el saludo según la hora del día
    const hour = new Date().getHours();
    if (hour < 12) {
      setGreeting('Buenos días');
    } else if (hour < 18) {
      setGreeting('Buenas tardes');
    } else {
      setGreeting('Buenas noches');
    }
  }, []);

  // Cantidad de notificaciones
  const notifications = [
    { title: "Actualización Disponible", description: "Nueva versión de la app ya lista para descargar." },
    { title: "Consejos de Seguridad", description: "Recuerda usar el cinturón de seguridad." },
    { title: "Congestión en Ruta", description: "Evita la calle 5 durante las horas pico." },
    { title: "Revisa Tu Itinerario", description: "Asegúrate de que tus rutas estén actualizadas." },
  ];

  return (
    <div className="w-full bg-transparent p-6 text-gray-800 relative" style={{ marginBottom: "-15px" }}>
      <div className="flex justify-between items-center">
        {/* Texto del encabezado */}
        <div>
          <h1 className="text-lg font-bold">{greeting},</h1>
          <p className="text-sm text-gray-600">{randomPhrase}</p>
        </div>

        {/* Icono de notificación con menú desplegable */}
        <div className="relative">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="bg-transparent text-gray-800 p-2 relative focus:outline-none"
          >
            <FiBell className="text-2xl" />
            {/* Punto de notificaciones */}
            {notifications.length > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-600 rounded-full h-2 w-2" />
            )}
          </button>

          {/* Menú desplegable de notificaciones */}
          {showDropdown && (
            <div
              className="absolute right-0 mt-2 w-64 bg-white text-gray-800 rounded-lg shadow-lg z-10 transform transition-transform duration-200 ease-out scale-100 origin-top-right"
              onBlur={() => setShowDropdown(false)}
              tabIndex="0"
            >
              <div className="px-4 py-3 border-b border-gray-200 font-semibold text-lg">Notificaciones</div>
              <ul className="max-h-48 overflow-y-auto">
                {notifications.slice(0, 4).map((notification, index) => (
                  <li
                    key={index}
                    className="px-4 py-3 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                  >
                    <h4 className="font-semibold">{notification.title}</h4>
                    <p className="text-sm text-gray-600">{notification.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
