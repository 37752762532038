import React, { useState } from 'react';
import { FaIdCard, FaCar, FaBuilding, FaPassport } from 'react-icons/fa';
import HeaderGlobal from '../components/HeaderGlobal';

const ConsultarMultas = () => {
  const [selectedOption, setSelectedOption] = useState('CED');
  const [placeholder, setPlaceholder] = useState('Ingrese Identificación');
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Manejar el cambio de opción en el select
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setInputValue('');
    setErrorMessage('');

    // Cambiar el placeholder según la opción seleccionada
    switch (value) {
      case 'CED':
        setPlaceholder('Ingrese Licencia de Conducir de Ecuador');
        break;
      case 'PLA':
        setPlaceholder('Ingrese la Placa de Ecuador');
        break;
      case 'RUC':
        setPlaceholder('Ingrese RUC de Ecuador');
        break;
      case 'PAS':
        setPlaceholder('Ingrese el Pasaporte');
        break;
      default:
        setPlaceholder('Ingrese Identificación');
    }
  };

  // Validar la entrada del usuario
  const validateInput = () => {
    let regex;
    switch (selectedOption) {
      case 'CED':
        regex = /^[0-9]{10}$/; // Licencia: 10 dígitos numéricos
        if (!regex.test(inputValue)) {
          setErrorMessage('La licencia debe tener 10 dígitos numéricos.');
          return false;
        }
        break;
      case 'PLA':
        regex = /^[A-Z]{3}[0-9]{4}$/; // Placa: 3 letras y 4 números
        if (!regex.test(inputValue)) {
          setErrorMessage('La placa debe tener 3 letras seguidas de 4 números. Ej: ABC1234.');
          return false;
        }
        break;
      case 'RUC':
        regex = /^[0-9]{13}$/; // RUC: 13 dígitos numéricos
        if (!regex.test(inputValue)) {
          setErrorMessage('El RUC debe tener 13 dígitos numéricos.');
          return false;
        }
        break;
      case 'PAS':
        regex = /^[A-Z0-9]+$/; // Pasaporte: letras y/o números
        if (!regex.test(inputValue)) {
          setErrorMessage('El pasaporte debe contener solo letras y/o números.');
          return false;
        }
        break;
      default:
        return true;
    }
    setErrorMessage('');
    return true;
  };

  // Manejar la entrada del usuario
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Manejar la presentación del formulario
  const handleSubmit = (event) => {
    if (!validateInput()) {
      event.preventDefault(); // Evitar el envío si la validación falla
    }
  };

  return (
    <div>
      <HeaderGlobal />
      <div className="flex items-center justify-center min-h-screen bg-gray-100 py-10">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md m-3">
          <h2 className="text-2xl font-bold text-center mb-6">Consulta de Multas</h2>
          <form
            action="https://sistematransito.ant.gob.ec/PortalWEB/paginas/clientes/clp_grid_citaciones.jsp"
            method="POST"
            className="space-y-4"
            onSubmit={handleSubmit}
          >
            {/* Select para elegir el tipo de identificación */}
            <div className="flex flex-col">
              <label className="text-sm font-medium mb-1">Tipo de Identificación</label>
              <select
                value={selectedOption}
                onChange={handleSelectChange}
                className="p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="CED">Licencia</option>
                <option value="PLA">Placa</option>
                <option value="RUC">RUC</option>
                <option value="PAS">Pasaporte</option>
              </select>
              <input type="hidden" name="ps_tipo_identificacion" value={selectedOption} readOnly />
            </div>

            {/* Input de identificación */}
            <div className="relative">
              <input
                type={
                  selectedOption === 'PLA'
                    ? 'text'
                    : selectedOption === 'PAS'
                    ? 'text'
                    : 'number'
                } // Mostrar teclado numérico para 'CED' y 'RUC', normal para 'PLA' y 'PAS'
                pattern={
                  selectedOption === 'PLA'
                    ? '[A-Z]{3}[0-9]{4}' // Patrón de placa
                    : undefined
                }
                name="ps_identificacion"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                required
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {/* Icono dinámico */}
              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400">
                {selectedOption === 'CED' && <FaIdCard />}
                {selectedOption === 'PLA' && <FaCar />}
                {selectedOption === 'RUC' && <FaBuilding />}
                {selectedOption === 'PAS' && <FaPassport />}
              </div>
            </div>

            {/* Mensaje de error */}
            {errorMessage && (
              <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
            )}

            {/* Botón de búsqueda */}
            <button
              type="submit"
              className="w-full bg-gray-800 text-white py-2 rounded-md hover:bg-gray-600 transition-colors"
            >
              Buscar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConsultarMultas;
