import React from 'react';
import { FiGrid, FiStar, FiHeart } from 'react-icons/fi';

const MenuTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex justify-between items-center mt-5">
      <div className="flex space-x-1">
        <button
          className={`flex items-center px-3 py-2 rounded-full ${
            activeTab === 'Todos' ? 'bg-gray-800 text-white' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('Todos')}
        >
          <FiGrid className="mr-2" />
          Todos
        </button>
        <button
          className={`flex items-center px-3 py-2 rounded-full ${
            activeTab === 'Mas Visto' ? 'bg-gray-800 text-white' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('Mas Visto')}
        >
          <FiStar className="mr-2" />
          Más Visto
        </button>
        {/* <button
          className={`flex items-center px-3 py-2 rounded-full ${
            activeTab === 'Favoritos' ? 'bg-gray-800 text-white' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('Favoritos')}
        >
          <FiHeart className="mr-2" />
          Favoritos
        </button> */}
      </div>
    </div>
  );
};

export default MenuTabs;
